import { Box, Text } from '@air/zephyr';

import { Container } from '~/old-marketing/components/Container';
import { Section } from '~/old-marketing/components/Section';

export const Tagline = () => {
  return (
    <Section
      tx={{
        backgroundColor: 'flamingo050',
        backgroundImage:
          'url(https://images.ctfassets.net/c1d9eptjtfvv/7xRazlMbmefY4EL79BJkHo/0360049f49c19ba63eb516f73a3ff98c/creatives-tagline.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        pt: [72, 96, 96],
        pb: [80, 156, 156],
      }}
    >
      <Container tx={{ maxWidth: 820, textAlign: 'center' }}>
        <Text as="h2" tx={{ color: '#1A0F2E', mb: 24 }} variant={['text-heading-32', 'text-heading-48']}>
          Finding the{' '}
          <Box as="span" tx={{ color: 'cuckoo300' }}>
            right freelancer
          </Box>{' '}
          is too damn difficult.
        </Text>
        <Text tx={{ color: 'pigeon700' }} variant={['text-ui-18', 'text-ui-20']}>
          {`We’re here to fix that. Browse our library of creative freelancers — recommended by marketers, founders, and creative directors behind some of the most distinct brands in NYC. It’s time to crack open the digital rolodex!`}
        </Text>
      </Container>
    </Section>
  );
};
