import { AnalyticsPageRouting, AnalyticsProvider } from '@air/marketing-analytics';
import { Logo } from '@air/marketing-primitives';
import { Box, Text } from '@air/zephyr';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { ReactNode } from 'react';

import { Container } from '~/old-marketing/components/Container';
import { GlobalStyle } from '~/old-marketing/components/GlobalStyle';
import { InternalLink } from '~/old-marketing/components/Link';
import { Routes } from '~/old-marketing/constants/Routes';
import { GoogleTagManager } from '~/scripts/GoogleTagManager';

const Header = () => {
  return (
    <Box as="header" tx={{ position: 'absolute', top: 0, left: 0, right: 0, py: 20 }}>
      <Container>
        <InternalLink href={Routes.root}>
          <Logo css={{ fill: '$peacock300', width: 72 }} />
        </InternalLink>
      </Container>
    </Box>
  );
};

const Footer = () => {
  return (
    <Box as="footer" tx={{ backgroundColor: '#1A0F2E', py: 40 }}>
      <Container tx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Text tx={{ pt: 4, mr: 8, color: 'white', fontWeight: 'medium' }} variant="text-ui-18">
          Made with love by
        </Text>
        <InternalLink href={Routes.root}>
          <Logo css={{ fill: '$peacock300', height: 24 }} />
        </InternalLink>
      </Container>
    </Box>
  );
};

export type NYCCreativeCouncilLayoutProps = {
  children: ReactNode;
};

export const NYCCreativeCouncilLayout = ({ children }: NYCCreativeCouncilLayoutProps) => {
  return (
    <AnalyticsProvider>
      <AnalyticsPageRouting />
      <GlobalStyle />
      <NextSeo
        title="NYC Creative Council"
        description="Hire freelance creatives, recommended by brands you know and love"
        openGraph={{
          title: 'NYC Creative Council',
          description: 'Hire freelance creatives, recommended by brands you know and love',
          images: [
            {
              width: 1200,
              height: 630,
              url: 'https://images.ctfassets.net/c1d9eptjtfvv/31JTkUmUaaFLKKJ6aJvztC/9251e22c9f3dd7c4f4bf3037c7241305/Frame_254.png?w=600',
            },
          ],
        }}
      />

      <GoogleTagManager />
      <Head>
        <link href="/fonts/brice/brice.css" rel="stylesheet" key="gelica-font" />
      </Head>
      <Header />
      {children}
      <Footer />
    </AnalyticsProvider>
  );
};
