import { MarketingSectionParams, useTrackViewedMarketingSection } from '@air/marketing-analytics';
import { Box, BoxProps } from '@air/zephyr';
import { useRouter } from 'next/router';
import { IntersectionOptions, InView } from 'react-intersection-observer';

export interface SectionProps extends Omit<BoxProps, 'as'>, Pick<IntersectionOptions, 'threshold' | 'triggerOnce'> {
  analytics?: MarketingSectionParams;
  index?: number;
  onInView?: (inView: boolean, entry: IntersectionObserverEntry) => void;
}

export const Section = ({
  analytics,
  children,
  index,
  onInView,
  threshold = 0,
  triggerOnce = true,
  tx,
  ...restOfProps
}: SectionProps) => {
  const { asPath } = useRouter();
  const { trackViewedMarketingSection } = useTrackViewedMarketingSection();

  const onChange = (inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView) {
      trackViewedMarketingSection({
        location: window.location.href,
        order: index,
        pathName: asPath,
        ...analytics,
      });
    }

    if (onInView) {
      onInView(inView, entry);
    }
  };

  return (
    <InView as="section" onChange={onChange} threshold={threshold} triggerOnce={triggerOnce}>
      <Box
        {...restOfProps}
        tx={{
          zIndex: 0,
          position: 'relative',
          py: [64, 64, 80],
          overflow: 'hidden',
          ...tx,
        }}
      >
        {children}
      </Box>
    </InView>
  );
};
