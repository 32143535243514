import { Box, BoxProps, Button } from '@air/zephyr';

import filters from '~/lib/creatives/filters-data-formatted.json';

export interface ListFilterProps extends BoxProps {
  activeFilters?: string[];
  onChangeFilter: (value: string) => void;
}

export const ListFilter = ({ activeFilters, onChangeFilter, tx, ...restOfProps }: ListFilterProps) => {
  const numberOfFilters = activeFilters?.length ?? 0;
  const hasFilters = numberOfFilters > 0;

  return (
    <Box {...restOfProps} tx={{ mx: 'auto', mb: 64, textAlign: 'center', ...tx }}>
      <Button
        onClick={() => onChangeFilter('all')}
        tx={{
          backgroundColor: !hasFilters ? 'macaw080' : 'transparent',
          mx: 12,
          mb: 12,
          borderColor: 'macaw080',
          color: !hasFilters ? 'jay500' : 'macaw100',
        }}
        variant="button-outline-blue"
      >
        All
      </Button>
      {filters.map((filter) => {
        const isActiveFilters = activeFilters?.includes(filter);

        return (
          <Button
            key={filter}
            onClick={() => onChangeFilter(filter)}
            tx={{
              backgroundColor: isActiveFilters ? 'macaw080' : 'transparent',
              mr: 12,
              mb: 12,
              borderColor: 'macaw080',
              color: !isActiveFilters ? 'macaw100' : 'jay500',
              '&:last-child': {
                mr: 0,
              },
            }}
            variant="button-outline-blue"
          >
            {filter}
          </Button>
        );
      })}
    </Box>
  );
};
