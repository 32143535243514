import { Box, Button, Text } from '@air/zephyr';
import { includes, without } from 'lodash';
import { useMemo, useState } from 'react';

import creatives from '~/lib/creatives/creatives-data-formatted.json';
import { Container } from '~/old-marketing/components/Container';
import { CreativeCard } from '~/old-marketing/components/NYCCreativeCouncil/CreativeCard';
import { ListFilter } from '~/old-marketing/components/NYCCreativeCouncil/ListFilter';
import { Section } from '~/old-marketing/components/Section';

export const List = () => {
  const [filters, setFilters] = useState<string[]>([]);
  const [amountToShow, setAmountToShow] = useState(1);
  const hasFilters = filters.length > 0;

  const [filteredCreatives, hasShowMore] = useMemo(() => {
    if (!hasFilters) {
      const slicedCreatives = creatives.slice(0, 12 * amountToShow);
      const hasShowMore = creatives.length > slicedCreatives.length;

      return [slicedCreatives, hasShowMore];
    } else {
      const slicedCreatives = creatives
        .filter((creative) => {
          let hasMatch = false;

          for (const tag of creative.tags) {
            if (includes(filters, tag)) {
              hasMatch = true;
            }
          }

          return hasMatch;
        })
        .slice(0, 12 * amountToShow);
      const hasShowMore = creatives.length > slicedCreatives.length;
      return [slicedCreatives, hasShowMore];
    }
  }, [amountToShow, hasFilters, filters]);

  const onChangeFilter = (value: string) => {
    if (value === 'all') {
      setFilters([]);
    } else if (includes(filters, value)) {
      setFilters(without(filters, value));
    } else {
      setFilters([...filters, value]);
    }

    setAmountToShow(1);
  };

  const onShowMore = () => setAmountToShow(amountToShow + 1);

  return (
    <Section tx={{ backgroundColor: '#1A0F2E' }}>
      <Container>
        <Box tx={{ textAlign: ['left', 'center'] }}>
          <Text as="h2" tx={{ mb: 16, color: 'flamingo050' }} variant={['text-heading-32', 'text-heading-48']}>
            The List
          </Text>
          <Text tx={{ mb: 48, color: 'white' }} variant="text-ui-20">
            <Box>Have a specific project in mind? Browse our full library of freelance creatives below. </Box>
            <Box>Select one or more filters to narrow down your search.</Box>
          </Text>
        </Box>

        <ListFilter activeFilters={filters} onChangeFilter={onChangeFilter} />

        <Box
          tx={{
            display: 'grid',
            gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            columnGap: 40,
            rowGap: 40,
            mb: 64,
          }}
        >
          {filteredCreatives.map((creative, index) => {
            return (
              <CreativeCard
                avatar={creative.avatar}
                name={creative.name}
                images={creative.images}
                key={`${index}-${creative.name}`}
                showImage
                social={creative.social}
                tags={creative.tags}
              />
            );
          })}
        </Box>

        {hasShowMore && (
          <Box tx={{ textAlign: 'center' }}>
            <Button
              onClick={onShowMore}
              tx={{ borderColor: 'macaw080', color: 'macaw100' }}
              variant="button-outline-blue"
            >
              Show more creatives
            </Button>
          </Box>
        )}
      </Container>
    </Section>
  );
};
