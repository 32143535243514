import { BoxProps } from '@air/zephyr';

import { StyledContainer } from '~/old-marketing/components/Container.styles';

export interface ContainerProps extends BoxProps {}

/**
 * @deprecated Use `marketing/components/globals/container` instead.
 */
export const Container = (props: ContainerProps) => <StyledContainer {...props} />;
