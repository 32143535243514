import { Box, BoxProps, Text } from '@air/zephyr';
import Image from 'next/image';

export interface BrandCardProps extends BoxProps {
  image?: string;
  name?: string;
}

export const BrandCard = ({ image, name, tx, ...restOfProps }: BrandCardProps) => {
  return (
    <Box
      {...restOfProps}
      tx={{ backgroundColor: 'pigeon025', borderRadius: 8, overflow: 'hidden', cursor: 'pointer', ...tx }}
    >
      {image && (
        <Box tx={{ width: '100%', '> span': { display: 'block !important' }, img: { objectFit: 'cover' } }}>
          <Image alt={`${name}'s work`} height={260} width={260} src={image} />
        </Box>
      )}
      <Text tx={{ py: 12, fontWeight: 'semibold' }} variant="text-ui-14-uppercase">
        {name}
      </Text>
    </Box>
  );
};
