import { Box } from '@air/zephyr';
import css from '@styled-system/css';
import styled from 'styled-components';

export const StyledContainer = styled(Box)(
  css({
    maxWidth: 1224,
    mx: 'auto',
    px: 32,
  }),
);
