import { Box, Text } from '@air/zephyr';
import Image from 'next/image';

import { Container } from '~/old-marketing/components/Container';

export const Hero = () => {
  return (
    <Box as="section" tx={{ backgroundColor: '#1A0F2E', pt: [120, 104], pb: [80, 128] }}>
      <Container
        tx={{
          display: 'flex',
          alignItems: ['flex-start', 'center'],
          flexDirection: ['column', 'row'],
        }}
      >
        <Box tx={{ maxWidth: [480, 'unset'], mb: [28, 0], mr: [0, 104] }}>
          <Image
            alt="NYC Creative Council text"
            src="https://images.ctfassets.net/c1d9eptjtfvv/4VhZ4g2jRtoamqZcgUJBjE/e8cc662679022d343fd16c8a346d597a/nyc-creative-council.png"
            width={705}
            height={498}
          />
        </Box>

        <Box tx={{ maxWidth: 290 }}>
          <Text tx={{ color: 'white', fontWeight: ['medium', 'medium'] }} variant={['text-ui-20', 'text-ui-24']}>
            Hire freelance
            <br />
            creatives
          </Text>
          <Box
            tx={{
              width: '100%',
              maxWidth: 240,
              height: [2, 4],
              backgroundColor: 'peacock300',
              my: 48,
              borderRadius: 999,
            }}
          />
          <Text tx={{ color: 'white', fontWeight: ['medium', 'medium'] }} variant={['text-ui-20', 'text-ui-24']}>
            Recommended by
            <br />
            brands you know and love
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
