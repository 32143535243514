import { css } from '@styled-system/css';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${css({
    body: {
      color: 'pigeon700',
      backgroundColor: '#F6F6F6',
      fontFamily: 'copy',
    },
  })}
`;
