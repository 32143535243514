import {
  Behance as BehanceIcon,
  Dribbble as DribbbleIcon,
  Instagram as InstagramIcon,
  Link as LinkIcon,
  LinkedIn as LinkedInIcon,
  Medium as MediumIcon,
  Twitter as TwitterIcon,
} from '@air/icons';
import { Box, BoxProps, Text } from '@air/zephyr';
import { useReducedMotion } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';
import { useInterval } from 'react-use';

import { ExternalLink } from '~/old-marketing/components/Link';

export interface CreativeCardProps extends BoxProps {
  avatar?: string | null;
  images?: (string | null)[];
  name: string;
  showImage?: boolean;
  social?: {
    behance?: string | null;
    dribbble?: string | null;
    instagram?: string | null;
    linkedIn?: string | null;
    medium?: string | null;
    twitter?: string | null;
    website?: string | null;
  };
  tags?: string[];
}

interface ImageCycleProps extends BoxProps {
  name: string;
  images?: (string | null)[];
}

const ImageCycle = ({ name, images }: ImageCycleProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const shouldReduceMotion = useReducedMotion();

  const filteredImages = images?.filter((image) => !!image);

  const numberOfImages = filteredImages?.length ?? 0;
  const hasImages = numberOfImages > 0;

  useInterval(
    () => {
      if (activeImage === numberOfImages - 1) return setActiveImage(0);

      return setActiveImage(activeImage + 1);
    },
    isHovered && hasImages ? (shouldReduceMotion ? 1000 : 250) : null,
  );

  const onMouseEnter = () => setIsHovered(true);

  const onMouseLeave = () => setIsHovered(false);

  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tx={{
        position: 'relative',
        mb: hasImages ? 12 : 0,
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      {filteredImages?.map((image, index) => {
        return (
          <Box
            key={index}
            tx={{
              position: index > 0 ? 'absolute' : 'relative',
              top: 0,
              left: 0,
              opacity: activeImage === index ? 1 : 0,
              width: '100%',
              '> span': { display: 'block !important' },
              img: { objectFit: 'cover' },
            }}
          >
            <Image alt={`An example of ${name}'s work #${index}`} height={260} width={260} src={image!} />
          </Box>
        );
      })}
    </Box>
  );
};

export const CreativeCard = ({
  avatar,
  images,
  name,
  showImage,
  social,
  tags,
  tx,
  ...restOfProps
}: CreativeCardProps) => {
  return (
    <Box {...restOfProps} tx={{ color: 'flamingo050', ...tx }}>
      {showImage && <ImageCycle name={name} images={images} />}

      <Box tx={{ display: 'flex', alignItems: 'flex-start' }}>
        {avatar && (
          <Box tx={{ width: 56, minWidth: 56, height: 56, borderRadius: 999, overflow: 'hidden', mr: 16 }}>
            <Image alt={name} height={56} src={avatar} width={56} />
          </Box>
        )}

        <Box>
          <Text tx={{ mb: 4, color: 'inherit', fontWeight: 'semibold' }} variant="text-ui-18">
            {name}
          </Text>
          <Text tx={{ mb: 8, color: 'inherit' }} variant="text-ui-14">
            {tags?.join(', ')}
          </Text>
          <Box tx={{ display: 'flex' }}>
            {social?.website && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.website}>
                <LinkIcon width="16" />
              </ExternalLink>
            )}
            {social?.instagram && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.instagram}>
                <InstagramIcon width="16" />
              </ExternalLink>
            )}
            {social?.twitter && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.twitter}>
                <TwitterIcon width="16" />
              </ExternalLink>
            )}
            {social?.linkedIn && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.linkedIn}>
                <LinkedInIcon width="16" />
              </ExternalLink>
            )}
            {social?.medium && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.medium}>
                <MediumIcon width="16" />
              </ExternalLink>
            )}
            {social?.behance && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.behance}>
                <BehanceIcon width="16" />
              </ExternalLink>
            )}
            {social?.dribbble && (
              <ExternalLink tx={{ mr: 12, color: 'inherit' }} href={social.dribbble}>
                <DribbbleIcon width="16" />
              </ExternalLink>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
