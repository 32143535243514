import { LinkedIn as LinkedInIcon } from '@air/icons';
import { VisuallyHidden } from '@air/marketing-primitives';
import { Box, Modal, ModalProps, Text } from '@air/zephyr';
import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';

import brands from '~/lib/creatives/brands-data-formatted.json';
import creatives from '~/lib/creatives/creatives-data-formatted.json';
import { Container } from '~/old-marketing/components/Container';
import { ExternalLink } from '~/old-marketing/components/Link';
import { BrandCard } from '~/old-marketing/components/NYCCreativeCouncil/BrandCard';
import { CreativeCard } from '~/old-marketing/components/NYCCreativeCouncil/CreativeCard';
import { Section } from '~/old-marketing/components/Section';

interface BrandModalProps extends Pick<ModalProps, 'onDismiss'> {
  data?: typeof brands[0];
}

const BrandModal = ({ data, onDismiss }: BrandModalProps) => {
  if (!data) return null;

  return (
    <Modal
      tx={{ width: '90%', maxWidth: 800 }}
      onDismiss={onDismiss}
      isAlertModal={false}
      modalLabel={<VisuallyHidden>Brand modal</VisuallyHidden>}
      modalDescription={
        <VisuallyHidden>
          A modal that shows information on the brand and a list of creatives that they recommend.
        </VisuallyHidden>
      }
      variant="modal-large"
      withCloseButton={false}
    >
      <Box tx={{ display: 'grid', gridTemplateColumns: ['1fr', 'repeat(2,1fr)'], gridGap: 32, mb: [32, 56] }}>
        {data?.image && (
          <Box tx={{ img: { objectFit: 'cover' } }}>
            <Image height={260} src={data?.image} width={360} />
          </Box>
        )}

        <Box tx={{ mt: [0, 20] }}>
          <Text tx={{ mb: 4, color: 'pigeon700', fontWeight: 'medium' }} variant="text-ui-24">
            {data?.contact?.name}
          </Text>
          <Text tx={{ mb: 12, color: 'pigeon500' }} variant="text-ui-16">
            {data?.contact?.role} at{' '}
            <ExternalLink href={data?.website} tx={{ color: 'inherit', fontWeight: 'inherit' }}>
              {data?.name}
            </ExternalLink>
          </Text>
          <Text tx={{ color: 'pigeon600' }} variant="text-ui-16">
            {data?.description}
          </Text>
          {data?.linkedIn && (
            <Box tx={{ mt: 12 }}>
              <ExternalLink href={data.linkedIn}>
                <LinkedInIcon width="24" />
              </ExternalLink>
            </Box>
          )}
        </Box>
      </Box>

      <Text tx={{ mb: 24, fontWeight: 'medium' }} variant="text-ui-20">
        Recommend creatives
      </Text>
      <Box
        tx={{
          display: 'grid',
          gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
          columnGap: 40,
          rowGap: 40,
        }}
      >
        {creatives
          .filter((creative) => {
            if (!data?.name) return false;

            return creative.brandRecommender.includes(data?.name);
          })
          .map((creative, index) => {
            return (
              <CreativeCard
                avatar={creative.avatar}
                name={creative.name}
                key={`${index}-${creative.name}`}
                social={creative.social}
                tags={creative.tags}
                tx={{ color: 'pigeon600' }}
              />
            );
          })}
      </Box>
    </Modal>
  );
};

export const Brands = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeBrand, setActiveBrand] = useState<typeof brands[0] | undefined>(undefined);

  const onDimissModal = () => setIsModalOpen(false);

  return (
    <>
      <Section>
        <Container tx={{ textAlign: 'center' }}>
          <Text as="h2" tx={{ mb: 12, color: '#1A0F2E' }} variant={['text-heading-32', 'text-heading-48']}>
            The Brands
          </Text>
          <Text tx={{ mb: 56, color: 'pigeon600' }} variant="text-ui-20">
            <Box as="p">Imitation is the sincerest form of flattery, right?</Box>
            <Box as="p">Click on the brand cards below to see their go-to list of freelance creatives.</Box>
          </Text>

          <Box
            tx={{
              display: 'grid',
              gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
              columnGap: 40,
              rowGap: 40,
              mb: 64,
            }}
          >
            {brands
              .filter((brand) => !!brand)
              .map((brand) => {
                const onClick = () => {
                  setActiveBrand(brand);
                  setIsModalOpen(true);
                };

                return <BrandCard image={brand?.image} key={brand?.name} name={brand?.name} onClick={onClick} />;
              })}
          </Box>
        </Container>
      </Section>
      <AnimatePresence>{isModalOpen && <BrandModal data={activeBrand} onDismiss={onDimissModal} />}</AnimatePresence>
    </>
  );
};
