import { NYCCreativeCouncilLayout } from '~/layouts/NYCCreativeCouncilLayout';
import { Brands } from '~/old-marketing/components/NYCCreativeCouncil/Brands';
import { Hero } from '~/old-marketing/components/NYCCreativeCouncil/Hero';
import { List } from '~/old-marketing/components/NYCCreativeCouncil/List';
import { Tagline } from '~/old-marketing/components/NYCCreativeCouncil/Tagline';

export default function NYCCreativeCouncilPage() {
  return (
    <>
      <Hero />
      <Tagline />
      <Brands />
      <List />
    </>
  );
}

NYCCreativeCouncilPage.layout = NYCCreativeCouncilLayout;
